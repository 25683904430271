import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Email.css';

import { MdOutlineMarkEmailRead } from "react-icons/md";




import Aos from 'aos'
import 'aos/dist/aos.css';

export const Email = () => {
  const form = useRef();


  function getItem(key) {
    // getting the data from localStorage using the key
    let result = JSON.parse(window.localStorage.getItem(key));

    if (result) {
      /*
          if data expireTime is less then current time
          means item has expired,
          in this case removing the item using the key
          and return the null.
      */
      if (result.expireTime <= Date.now()) {
        window.localStorage.removeItem(key);
        return null;
      }
      // else return the data.
      return result.data;
    }
    //if there is no data provided the key, return null.
    return null;
  }

  /*
      accepting the key, value and expiry time as a parameter
      default expiry time is 86400000 is 1 days in milliseconds.
  */
  function setItem(key, value, maxAge = 1 * 60 * 60 * 1000) {
    // Storing the value in object.
    let result =
    {
      data: value
    }

    if (maxAge) {
      /*
          setting the expireTime currentTime + expiry Time 
          provided when method was called.
      */
      result.expireTime = Date.now() + maxAge;
    }
    window.localStorage.setItem(key, JSON.stringify(result));
  }



  const [emailSent, setEmailSent] = useState(getItem("emailSent") || false)

  const sendEmail = (e) => {
    e.preventDefault();


    emailjs
      .sendForm('service_9r68wej', 'template_lzuy4ao', form.current, {
        publicKey: 'rEkbRrDFinvnp2En1',
      })
      .then(
        () => {
          e.target.reset()
          setEmailSent(true)
          setItem("emailSent", true)
          // localStorage.setItem("emailSent", JSON.stringify(true));

          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section id="consult_us">

        <div data-aos="fade-right" class="noteabout">#Consult_Us\</div>
        <div class="emailcontant" >
          <div data-aos="fade-up" className="consultTitel">Warning</div>
          <p data-aos="fade-up" data-aos-duration="3500" className="consultDis"><br />Make sure you have added your correct information and valid Eamil, we'll response you as soon as we can.<br />
            You could resend a new messages after 1 hour
          </p>
        </div>
        <div class="Background">
          {!emailSent ? <div data-aos="fade-right" className="emailForm">

            <form className='form ' ref={form} onSubmit={sendEmail}>
              <label className='fildeTiltel' >Name</label>
              <input className=' filde' type="text" name="user_name" required />
              <label className='fildeTiltel'>Email</label>
              <input className=' filde' type="email" name="user_email" required />
              <label className='fildeTiltel'>Phone</label>
              <input className=' filde' type="tel" name="user_phone" required />
              <label className='fildeTiltel'>Message</label>
              <textarea className='message' name="message" required />
              {/* <button className=' sendButton' type="button"  >Send</button > */}
              <input className='sendButton' type="submit" value="Send" />
            </form>
          </div> : <div data-aos="fade-up" style={{ marginTop: "160px" }} className="emailForm text"><MdOutlineMarkEmailRead size={60} /><div>Your consultation request email has been sent successfully.</div>
          </div>}




          <div class="Frame2" id="about_1"></div>
          <div class="Frame3" id="about_2"></div>
          <div class="Frame2" id="about_3"></div>
          <div class="Frame2" id="about_4"></div>
          <div class="Frame2" id="about_5"></div>
          <div class="Frame2" id="about_6"></div>
          <div class="Frame2" id="about_7"></div>
        </div>

      </section>
    </>

  )
}


export default Email