import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './components/header/Navbar';
import Home from './components/section/Home/Home';
import Services from './components/section/Services/Services';
import Projects from './components/section/Projects/Projects';
import About from './components/section/About/About';
import Contact from './components/section/Contact/Contact';

import { Helmet } from 'react-helmet';


import 'aos/dist/aos.css';
import Email from './components/section/Email/Email';

// npm start

// npm run build

// git init
// git remote remove origin

// git init
// git add --all 
// git commit -m "new"
// git push

// echo "# quinnbuild" >> README.md
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/Yeiba/quinnbuild.git

// git push -f origin main
// git push --set-upstream origin main
// git add .
// 

// …or push an existing repository from the command line

// git remote add origin https://github.com/Yeiba/quinnfuture.git
// git branch -M main
// git push -u origin main

// git config --global user.name "Yeiba"

// git config --global user.email "yeibayalda@gmail.com"

function App() {




  return (

    <div className="App">
      <Helmet>
        <meta name="viewport" content="width=device-width" />
        {/* <meta name='viewport' content='user-scalable=0' /> */}

        {/* <meta name="viewport" content="width=full, initial-scale=1" /> */}
        {/* <meta name="viewport" content="width=device-width, initial-scale=0" /> */}

        <meta
          name="description"
          content="Quinn Future,  We create wow future 
                                  NO NEED TO SPEND more hours ON JUST
                                  searching FOR IT & creative agency.
                                  our agency create beitching ideas.

                                  Big Data Solutions 
                                  DATA SCIENCE
                                  BIG DATA engineering

                                  WEB DEVELOPMENT
                                  UI/UX DESIGN
                                  WEB APPLICATION
                                  MOBIL APPLICATION
                                  DevOps engineering

                                  BRANDING
                                  BRAND STRATEGY
                                  LOGO DESIGN
                                  LOGO ANIMATION
                                  BRAND VISUAL IDENTETY
                                  SOCIAL MEDIA DESIGN
                                  SOCIAL MEDIA content

                                  marketing
                                  LEAD GENERATION
                                  DIGITAL MARKETING
                                  Event management
                                  Print marketing
                                  Content marketing
                                  marketing strategy

                                  ai generated art
                                  ai generated video
                                  ai generated images
                                  ai product design

                                  FILMMAKING
                                  ADVERTISING VIDEOS
                                  MUSIC VEDIOS
                                  PODCASTS ViDEOS
                                  cinematography
                                  documentary
                                  real estate

                                  3D DESIGN
                                  3D PRODUCTS
                                  3D MODELING
                                  3D ANIMATIONS
                                  real estate

                                  MUSIC PRODUCING
                                  RAP MUSIC
                                  POP MUSIC
                                  ROCK MUSIC
                                  ORCHESTRAL
                                  SOUND_DESIGN
                                  SFX
                                  RECORDING
                                  MASTERING

                                  indrive dz
                                  pce couveuse 

                                  Front end 

                                  React 
                                  React native
                                  Next js
                                  Tailwind css 
                                  Redux tool kit 
                                  shadcn ui 

                                  Back end 

                                  Next js
                                  Node js 
                                  Express js
                                  Nginx
                                  Apache kafka 
                                  Apache spark

                                  Database:

                                  Mongodb 
                                  Postgresql
                                  Redis
                                  Elasticsearch 

                                  DevOps 

                                  Github
                                  Kubernetes
                                  Openshift 
                                  Ducker
                                  Jenkins
                                  Anisble
                                  Terraform
                                  let's encrypt ssl tls

                                  Infrastructure 
                                  aws amazon web services
                                  AWS EKS 
                                  AWS EC2
                                  AWS ECS
                                  AWS S3 
                                  Openstack

                                  contact
                                  yaakoub belhardi
                                  CEO Founder
                                  software engineer
                                  yaakoubbelhardi@quinnfuture.com

                                  KAssia kaci
                                  Sales & Marketing Manager
                                  kassiakaci@quinnfuture.com
                                  
                                  facebook @quinnfuture
                                  instagram @quinnfuture
                                  behance @quinnfuture
                                  linkedin /company/quinnfuture
                                  
                                  " />
      </Helmet>
      <Navbar />
      <Home />
      <Services />
      <Projects />
      <About />
      <Email />
      <Contact />
    </div>

  );

}

export default App;
