import React, { useEffect, useState } from 'react';
import './Projects.css';



import Aos from 'aos'
import 'aos/dist/aos.css';

export const Projects = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    const partnership = [
        { name: 'InDrive', logo: './img/indrive-black.png', discription: 'Ridesharing company company' },
        { name: 'pce', logo: './img/pce-black.png', discription: 'Ridesharing company company' },
        { name: 'vague', logo: './img/vague-black.png', discription: 'Ridesharing company company' },
        { name: 'bricool', logo: './img/bricool-black.png', discription: 'Ridesharing company company' },
    ]
    return (
        <>
            <section id="work">

                <div data-aos="fade-right" class="note">#partners\</div>
                <div class="outer">
                    <div className="cont">
                        <div class="inner">
                            <div class="border_2_1" ></div>
                            {partnership.map((one) =>
                                <div class="boxproject">
                                    <h1 data-aos="fade-right" class="WorkH">
                                        {/* {one.name} <br /> */}
                                    </h1>
                                    <img data-aos="fade-right" alt="indrive" class="partnerlogo" src={one.logo} />
                                    <p data-aos="fade-up" class="partP">
                                        {/* • click • <br /> */}
                                        • {one.name} •  <br />
                                    </p>
                                </div>
                            )}
                            <div class="border_2_2" ></div>
                        </div>
                    </div>
                </div>
                <div class="Frame3" id="work_1"></div>
                <div class="Frame3" id="work_2"></div>
                <div class="Frame3" id="work_3"></div>
                <div class="Frame3" id="work_4"></div>

            </section>
        </>

    )
}


export default Projects